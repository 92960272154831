import { compose , applyMiddleware , createStore } from 'redux';
import {logger} from 'redux-logger';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './root-store';

const middlewares = [process.env.NODE_ENV !=='production' && logger ].filter(Boolean);
console.log(process.env.NODE_ENV, 'environment details');
console.log(process.env.REACT_APP_ENV, 'environment details');
const persistConfig = {
    key : 'root',
    storage,
}

const persistedReducter = persistReducer(persistConfig , rootReducer);
const composeEnhancers = compose(applyMiddleware(...middlewares));
export const store = createStore(persistedReducter , undefined , composeEnhancers);
export const persistor = persistStore(store);